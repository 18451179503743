import React, { Component } from 'react';
import HomeShowcase from "../Home/ShowCase/HomeShowcase";

import "./Shops.scss";

class Shops extends Component {
    render() {
        return (
            <>
                <div className="shops-container">
                    <div className="shops-container-title">
                        <p>Tutti I negozi</p>
                    </div>
                    <div className="shops-container-subtitle">
                        <p>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                            Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
                            ridiculus.
                            Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate
                            eget,
                            arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu
                            pede mollis pretium. Integer tincidunt. Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis
                            dis
                            parturient montes, nascetur ridiculus.
                        </p>
                    </div>
                    <HomeShowcase />
                </div>
            </>
        );
    }
}

export default Shops;