import React, {Component} from 'react';
import ChieriShopping from "../../images/chieri-shopping-nights.png";
import NewsDetailSideImg from "../../images/NewsDetailsSmall.png";
import Date from "../News/Date";
import NewsCard from "./NewsCard";

class NewsDetails extends Component {
    state = {
        isMobile: window.innerWidth <= 1024
    }

    constructor() {
        super();
        window.addEventListener("resize", (e) => {
            this.setState({isMobile: e.target.innerWidth <= 1024});
        });
    }

    render() {
        if (!this.state.isMobile) {
            return (
                <div className="container-fluid d-flex flex-row news-details">
                    <div className="col-8 d-flex flex-column news-details-container">
                        <div>
                            <Date/>
                        </div>
                        <div className="news-details-title">
                            <p>
                                Entra nel centro commerciale più grande d’Italia
                            </p>
                        </div>
                        <div className="news-details-subtitle">
                            <p>
                                Lorem ipsum dolor sit amet,
                                consectetuer adipiscing elit.
                                Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis
                                dis
                                parturient montes, nascetur ridiculus mus.
                            </p>
                        </div>
                        <div>
                            <img src={ChieriShopping} alt="" className="news-detail-images"/>
                        </div>
                        <div className="news-details-text">
                            <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget
                                dolor.
                                Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
                                ridiculus mus.
                                Nulla consequat massa quis enim enim. Donec pede justo, fringilla vel, aliquet nec, vulputate
                                eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
                                dictum
                                felis eu pede mollis pretium. Integer tincidunt.

                                Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                                Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate
                                eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
                                dictum
                                felis eu pede mollis pretium. <br/>

                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget
                                dolor.
                                Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
                                ridiculus mus.
                                Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate
                                eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
                                dictum
                                felis eu pede mollis pretium. Integer tincidunt.

                                Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                                Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate
                                eget,
                            </p>
                        </div>
                            <div>
                                <img src={ChieriShopping} alt="" className="news-detail-images"/>
                            </div>
                        <div className="news-details-text">
                            <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget
                                dolor.
                                Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
                                ridiculus mus.
                                Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate
                                eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
                                dictum
                                felis eu pede mollis pretium. Integer tincidunt.

                                Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                                Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, <br/>vulputate
                                eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
                                dictum
                                felis eu pede mollis pretium.

                                Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                                Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, <br/> vulputate
                                eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
                                dictum
                                felis eu pede mollis pretium.
                                Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                                Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, <br/> vulputate
                                eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
                                dictum
                                felis eu pede mollis pretium.

                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget
                                dolor.
                                Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
                                ridiculus mus. <br/>
                                Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate
                                eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
                                dictum
                                felis eu pede mollis pretium. Integer tincidunt.

                                Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                                Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate
                                eget,
                            </p>
                        </div>

                    </div>
                    <div className="col-4 d-flex flex-column">
                        <div className="news-details-side-heading">
                            <p>
                                Articoli correlati
                            </p>
                        </div>
                        <div className="news-detail-image">
                            <NewsCard className="news-detail-image-card"/>
                        </div>
                        <div className="news-detail-image">
                            <NewsCard className="news-detail-image-card"/>
                        </div>
                        <div className="news-detail-image">
                            <NewsCard className="news-detail-image-card"/>
                        </div>
                        <div className="news-detail-image">
                            <NewsCard className="news-detail-image-card"/>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<div className="container d-flex flex-column m-auto">
                <div>
                    <Date/>
                </div>
                <div className="news-details-mob-title">
                    Lorem ipsum dolor sit
                    amet, consectetuer
                    adipiscing elit. Aenean
                    commodo ligula eget dolor
                </div>
                <div className="news-details-mob-text">
                    Lorem ipsum dolor sit amet, consectetuer
                    adipiscing elit. Aenean commodo ligula eget
                    dolor. Aenean massa. Cum sociis natoque
                    penatibus et magnis dis parturient montes,
                    nascetur ridiculus mus.
                </div>
                <div>
                    <img src={NewsDetailSideImg} alt="" className="news-details-mob-image"/>
                </div>
                <div className="news-details-mob-text-block">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean
                    massa. Cumsociis natoque penatibus et magnis dis parturient montes,nascetur ridiculus.
                    Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
                    In enimjusto, rhoncus ut, imperdiet a, venenatis vitae, <br/> justo. Nullam dictum felis eu pede mollis
                    pretium. Integer tincidunt. Lorem ipsum dolor sit amet, consectetueradipiscing elit. Aenean commodo
                    ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes,
                    nascetur ridiculus.
                </div>
                <div>
                    <img src={NewsDetailSideImg} alt="" className="news-details-mob-image"/>
                </div>
                <div className="news-details-mob-text-block">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean
                    massa. Cumsociis natoque penatibus et magnis dis parturient montes,nascetur ridiculus.
                    Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
                    In enimjusto, rhoncus ut, imperdiet a, <br/> venenatis vitae, justo. Nullam dictum felis eu pede mollis
                    pretium. Integer tincidunt. Lorem ipsum dolor sit amet, consectetueradipiscing elit. Aenean commodo
                    ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes,
                    nascetur ridiculus.
                </div>
                <div className="news-detail-bottom-block">
                    Articoli correlati
                </div>
                <div className="row d-flex flex-row justify-content-center align-items-center">
                    <div className="col-6">
                        <NewsCard className="news-detail-image-card"/>
                    </div>
                    <div className="col-6">
                        <NewsCard className="news-detail-image-card"/>
                    </div>
                </div>
                <div className="row d-flex flex-row justify-content-center align-items-center">
                    <div className="col-6">
                        <NewsCard className="news-detail-image-card"/>
                    </div>
                    <div className="col-6">
                        <NewsCard className="news-detail-image-card"/>
                    </div>
                </div>
            </div>)
        }
    }
}

export default NewsDetails;