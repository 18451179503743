import React from 'react';
import moment from "moment";

import "./News.scss";

class App extends React.Component {
    state = {
        date: ""
    };

    componentDidMount() {
        let date = {currentTime: moment().format("DD MMMM YYYY")};

        this.setState({
            date: date
        });
    }

    render() {
        return (
            <div className="news-date">
                <p>{this.state.date.currentTime}</p>
            </div>
        );
    }
}

export default App;