import React, { Component } from "react";
import Locator from "../../images/locator.png";
import ShopImgPlaceHolder from "../../images/Placeholder_Chieri.jpg";
import { Translation } from "react-i18next";

import "./shop-card.styles.scss";

class ShopCard extends Component {
    render() {
        const { store } = this.props;
        return (
            <Translation>
                {t =>
                    <a href={store && (store.webUrl ? store.webUrl : `https://my-stores.it/shopDetails/${store.id}`)}
                        target="_blank" rel="noopener noreferrer">
                        <div className="shop-card">
                            <div className="shop-card-container">
                                <div className="image" alt=""
                                    style={{ backgroundImage: `url(${encodeURI(store.imageUrl)}),url(${ShopImgPlaceHolder})` }} />
                                <div>
                                    <div>
                                        <p className="abbigliamento-home-web">
                                            {store && store.shopCategoryList && store.shopCategoryList.length > 0 &&
                                                store.shopCategoryList.map(category => (
                                                    <span>#{category.description} </span>
                                                ))}
                                            {(!store || !store.shopCategoryList || store.shopCategoryList.length <= 0) &&
                                                <span>&nbsp;</span>
                                            }
                                        </p>
                                    </div>
                                    <div>
                                        <p className="shope-name-home-web">
                                            {store?.name || "NOME DEL NEGOZIO"}
                                        </p>
                                        <div className="d-flex flex-row justify-content-start align-items-lg-start mb-3">
                                            <div className="locator-icon">
                                                <img src={Locator} className="mb-2 ml-0" alt=""
                                                    style={{ width: "20px", height: "20px" }} />
                                            </div>
                                            <div className="shop-address-web ml-1">
                                                {store?.address.address} {store?.address.zip} {store?.address.city} {store?.address.county || "Via A. Manzoni 20, 00000, Chieri (TO)"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                }
            </Translation>
        );
    }
}

export default ShopCard;



