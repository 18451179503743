import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import banners from "./Service";
import ChieriBanner from "../../../images/banner-chieri.png";

export default class HomeCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: true,
            banners: []
        };
    }

    componentDidMount() {
        banners().then(response => {
            this.setState({
                isLoaded: true,
                //banners: response.data
            })
        });
    }

    render() {
        const { isLoaded } = this.state;
        return (isLoaded ?
            <Carousel className="Carousel">
                    <Carousel.Item>
                        <div>
                            <img src={ChieriBanner} alt="" style={{width:"100%", height:"100%"}}/>
                        </div>

                        {/*<div style={{backgroundImage: `url(${item.imageUrl})`}} alt=""></div>*/}
                    </Carousel.Item>
            </Carousel>

            : null
        );
    }
}