import React, {Component} from 'react';
import NewsMyStoreImg from "../../../images/news-my-stores@3x.png";
import ChieriImg from "../../../images/small.png";
import Slider from "react-slick";
import {ReactComponent as ArrowLeft} from "../../../images/arrow_left.svg";
import {ReactComponent as ArrowRight} from "../../../images/arrow_right.svg";
import {Link} from "react-router-dom";
import "./ShowcaseBlock.scss";
import Date from "../../News/Date";


class ShowCaseBlockTop extends Component {
    state = {
        isMobile: window.innerWidth < 1024
    }

    constructor() {
        super();
        window.addEventListener("resize", (e) => {
            this.setState({isMobile: e.target.innerWidth < 1024});
        });
    }

    render() {
        if (!this.state.isMobile) {
            return (
                <div className="showcase-slider">
                    <div className="d-flex justify-content-center m-5">
                        <div className="showcase-news">News</div>
                    </div>
                    <Slider {...settings}>
                        <div className="showcase-element">
                            <div className="d-flex flex-row justify-content-center mb-5 pl-3 pr-3">
                                <a href="https://news.my-stores.it/" className="link" rel="noopener noreferrer"
                                   target="_blank">
                                    <div>
                                        <img src={NewsMyStoreImg} className="slider-image-tab"
                                             style={{width: "547px", objectFit: "contain"}}
                                             alt=""/>
                                    </div>
                                </a>
                                <div className="ml-3">
                                    <div className="d-flex justify-content-start"><Date/></div>
                                    <a href="https://news.my-stores.it/"
                                       className="link"
                                       rel="noopener noreferrer"
                                       target="_blank" style={{textDecoration: "none"}}>
                                        <h4 className="news-title">Entra nel centro commerciale più grande d’Italia
                                        </h4>
                                    </a>
                                    <p className="news-text">
                                        My-Stores è la piattaforma in Cloud, di Attraction & Engagement, per Esercenti,
                                        Circuiti, Centri Commerciali Fisici e Naturali e Comunità Multi-Esercente. La
                                        tecnologia digitale, con sito ed eCommerce integrati, può rivelarsi un grande
                                        supporto allo sviluppo del retail tradizionale di prossimità dando vita ad un
                                        sistema «retail-centrico»….
                                    </p>
                                    <div className="showcase-bottom-button">
                                        <a href="https://news.my-stores.it/"
                                           className="link"
                                           rel="noopener noreferrer"
                                           target="_blank">
                                            <button>
                                                SCOPRI
                                            </button>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="showcase-element">
                            <div className="d-flex flex-row justify-content-center">
                                <Link to="/news/details">
                                    <div>
                                        <img src={ChieriImg} className="slider-image-tab"
                                             style={{width: "547px", objectFit: "contain"}} alt=""/>
                                    </div>
                                </Link>
                                <div className="ml-3">
                                    <div className="d-flex justify-content-start"><Date/></div>
                                    <Link to="/news/details" style={{textDecoration: "none"}}>
                                        <h4 className="news-title">Lorem ipsum dolor sit amet, consectetuer adipiscing
                                            elit.
                                            Aenean commodo ligula eget dolor.
                                        </h4>
                                    </Link>
                                    <p className="news-text">
                                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula
                                        eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient
                                        montes, nascetur ridiculus mus.
                                    </p>
                                    <div className="showcase-bottom-button">
                                        <Link to="/news/details">
                                            <button>
                                                SCOPRI
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    <div className="carousel-dots"></div>
                </div>
            )
        } else {
            return (
                <div className="showcase-slider mobile">
                    <div className="showcase-news-mob">News</div>
                    <Slider {...settings}>
                        <div className="showcase-element">
                            <a className="link" href="https://news.my-stores.it/"
                               rel="noopener noreferrer"
                               className="link"
                               target="_blank">
                                <div className="d-flex news-image-mob justify-content-center mb-2">
                                    <img src={NewsMyStoreImg} style={{objectFit: "contain"}} alt=""/>
                                </div>
                            </a>
                            <div className="d-flex justify-content-center"><Date/></div>
                            <div className="news-title-mob">
                                <a href="https://news.my-stores.it/"
                                   className="link"
                                   rel="noopener noreferrer"
                                   target="_blank" style={{textDecoration: "none", color: "black"}}>
                                    <h4> Entra nel centro commerciale più grande d’Italia</h4>
                                </a>
                            </div>
                            <div>
                                <p className="news-text-mob">
                                    My-Stores è la piattaforma in Cloud, di Attraction & Engagement, per Esercenti,
                                    Circuiti, Centri Commerciali Fisici e Naturali e Comunità Multi-Esercente. La
                                    tecnologia digitale, con sito ed eCommerce integrati, può rivelarsi un grande
                                    supporto allo sviluppo del retail tradizionale di prossimità dando vita ad un
                                    sistema «retail-centrico»….
                                </p>
                            </div>
                        </div>
                        <div className="showcase-element">
                            <div className="d-flex news-image-mob justify-content-center mb-2">
                                <Link to="/news/details" className="link" style={{textDecoration: "none"}}>
                                    <img src={ChieriImg} style={{objectFit: "contain"}} alt=""/>
                                </Link>
                            </div>
                            <div className="d-flex justify-content-center"><Date/></div>
                            <div className="news-title-mob">
                                <Link to="/news/details" className="link" style={{textDecoration: "none"}}>
                                    <h4>
                                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                                        elit.
                                        Aenean commodo ligula eget dolor.
                                    </h4>
                                </Link>
                            </div>
                            <div>
                                <p className="news-text-mob">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula
                                    eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient
                                    montes, nascetur ridiculus mus.
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            )
        }
    }
}

function appendDots(dots) {
    return (<div>
        <div className="carousel-dots">
            <ul>{dots}</ul>
        </div>
    </div>);
}

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: appendDots,
    nextArrow: <SampleArrow><ArrowRight/></SampleArrow>,
    prevArrow: <SampleArrow><ArrowLeft/></SampleArrow>,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                nextArrow: null,
                prevArrow: null,
                arrows: false
            }
        },
    ]
};

function SampleArrow(props) {
    const {className, style, onClick, children} = props;
    return (
        <div className={className} style={{...style}} onClick={onClick}>
            {children}
        </div>
    );
}

export default ShowCaseBlockTop;