import React from "react"
import { Translation } from "react-i18next";

const ReachUs = (props) => {
    return (
        <Translation>
            {t =>
                <div className="reach-us">
                    <div className="reach-us-img">
                        {props.icon}
                    </div>
                    <div className="reach-us-title">
                        {props.title}
                    </div>
                    <div className="reach-us-text">
                        {props.children}
                    </div>
                </div>
            }
        </Translation>
    );
}

export default ReachUs;