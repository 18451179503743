import React, {Component} from 'react';
import WhoWeAre from "../../images/who-we-are-image.png";
import ChieriBread from "../../images/chieri-bread.png";
import StoriaGridImg from "../../images/storia-grid.jpg";
import StoriaBanner from "../../images/storia-banner.jpg";
import StoriaImg from "../../images/storia-landing-img.png";
import StoriaMobImg from "../../images/storia-mob-img.jpg";

import "../Stories/Storia.scss"
import "./WhoWeAre-style.scss"

class StoriaContent extends Component {
    state = {
        isMobile: window.innerWidth <= 1024
    }

    constructor() {
        super();
        window.addEventListener("resize", (e) => {
            this.setState({isMobile: e.target.innerWidth <= 1024});
        });
    }

    render() {
        if (!this.state.isMobile) {
            return (
                <>
                    <div className="storia d-flex flex-column justify-content-center">
                        <div className="chi-siamo-title mb-1">
                            Chieri, tra tesori architettonici e Freisa
                        </div>
                        <div className="container">
                            <img className="storia-landing-img who-we-are-img" src={WhoWeAre}
                                 alt=""/>
                            <div className="chieri-image-title">Chieri, San Domenico </div>
                        </div>

                    </div>

                    <div>
                        <div className="chi-siamo-text">
                            <p>
                                Pochi minuti dividono Chieri da Torino. <b>Tra panorami e vigne </b> si giunge in quella
                                che
                                nell’XI secolo era <b>feudo di Landolfo, vescovo di Torino. Prosperò come centro
                                tessile, </b>
                                oggi celebrato nel Museo del tessile chierese, e proprio in quel luminoso periodo ebbe
                                grande impulso anche quello sviluppo architettonico che ancora oggi si ammira visitando
                                la città.

                            </p>
                            <p>
                                Autentico <b>tesoro di Chieri è il Duomo</b>, tra i più preziosi esempi
                                del <b>Quattrocento</b> piemontese, dalla facciata in cotto, con l’imponente campanile e il battistero. Ricco
                                anche l’interno, a tre navate, con opere di artisti chieresi e piemontesi, tra cui gli
                                affreschi che impreziosiscono il battistero, con scene della Passione di Cristo, <b>la
                                cappella di Gallieri</b>, alla base del campanile, con scene della vita di San Giovanni
                                Battista e tesori come reliquari e lavori di <b>oreficeria di provenienza fiamminga.</b>
                            </p>
                        </div>
                        <div className="container bread-div d-flex flex-row">
                        <div>
                            <img className="bread-img no-gutters" src={ChieriBread}
                                 alt=""/>
                            <div className="chieri-image-title">La focaccia di Chieri  </div>
                        </div>
                        <div>
                            <div className="bread-img-text">
                            <p>
                                Dal suo sagrato di ammira uno <b>splendido colpo d’occhio sulla città e sulle colline</b> che
                                fanno da cornice alla città, terre di vigne dove si produce la famosa <b>Freisa di Chieri
                                Doc</b>, vino rosso frizzante tipico della zona, acquistabile nelle tante cantine della zona
                                e servito nei ristoranti e nelle trattorie. Tra le delizie di Chieri non c’è solo
                                <b> l’ottima Freisa</b> ma anche la sua <b>deliziosa focaccia</b>, assolutamente da acquistare e
                                assaporare prima di salutare la città.
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <div className="storia container d-flex flex-column justify-content-center">
                    <div className="chi-siamo-title mb-1">
                        Chieri, tra tesori architettonici e Freisa
                    </div>
                    <div className="mt-3">
                        <img className="" src={WhoWeAre}
                             alt="" style={{width:"100%"}}/>
                        <div className="chieri-image-title">Chieri, San Domenico </div>
                    </div>


                    <div className="chi-siamo-text">
                        <p>
                            Pochi minuti dividono Chieri da Torino. <b>Tra panorami e vigne </b> si giunge in quella
                            che
                            nell’XI secolo era <b>feudo di Landolfo, vescovo di Torino. Prosperò come centro
                            tessile,</b>
                            oggi celebrato nel Museo del tessile chierese, e proprio in quel luminoso periodo ebbe
                            grande impulso anche quello sviluppo architettonico che ancora oggi si ammira visitando
                            la città.

                        </p>
                        <p>
                            Autentico <b>tesoro di Chieri è il Duomo</b>, tra i più preziosi esempi
                            del <b>Quattrocento</b> piemontese, dalla facciata in cotto, con l’imponente campanile e il battistero. Ricco
                            anche l’interno, a tre navate, con opere di artisti chieresi e piemontesi, tra cui gli
                            affreschi che impreziosiscono il battistero, con scene della Passione di Cristo, <b>la
                            cappella di Gallieri</b>, alla base del campanile, con scene della vita di San Giovanni
                            Battista e tesori come reliquari e lavori di <b>oreficeria di provenienza fiamminga.</b>
                        </p>
                    </div>
                    <div className="bread-div d-flex flex-column">
                        <div>
                            <img className="bread-img-mob no-gutters" src={ChieriBread} style={{width:"100%"}}
                                 alt=""/>
                            <div className="chieri-image-title">La focaccia di Chieri  </div>
                        </div>
                        <div>
                            <div className="bread-img-text-mob">
                                <p>
                                    Dal suo sagrato di ammira uno <b>splendido colpo d’occhio sulla città e sulle colline</b> che
                                    fanno da cornice alla città, terre di vigne dove si produce la famosa <b>Freisa di Chieri
                                    Doc</b>, vino rosso frizzante tipico della zona, acquistabile nelle tante cantine della zona
                                    e servito nei ristoranti e nelle trattorie. Tra le delizie di Chieri non c’è solo
                                    <b>l’ottima Freisa</b> ma anche la sua <b>deliziosa focaccia</b>, assolutamente da acquistare e
                                    assaporare prima di salutare la città.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            )
        }
    }
}

export default StoriaContent;