// Redux
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk"
import user from "../../redux/store/reducers/user"

const persistConfig = {
    key: "root",
    storage,
    whitelist: ['user', 'cart']
}

const rootReducer = combineReducers({
    user: user,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const reduxStore = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));

export const persistor = persistStore(reduxStore);

export default { reduxStore, persistor }; 