import React from "react";
import HomeCarousel from "../Home/HomeCarousel/HomeCarousel"
import CategorySlider from "../Categories/CategorySlider";
import { connect } from "react-redux"
import ShowCaseBlockBottom from "./ShowCase/ShowCaseBlockBottom";
import HomeShowcase from "./ShowCase/HomeShowcase";
import ShowCaseBlockTop from "./ShowCase/ShowCaseBlockTop";
import {Link} from "react-router-dom";

import "./Home.scss";


class Home extends React.Component {
    render() {
        return (
            <div className="mobile-view-search">
                <div className="search-stores-products">
                    <div className="text-center">
                        <HomeCarousel />
                        <CategorySlider />
                        <ShowCaseBlockBottom />
                        <div className="shops-container">
                            <div className="landing-stores-title">
                                <p>I nostri negozi</p>
                            </div>
                            <HomeShowcase />
                            <div className="showcase-button">
                                <Link to="/negozi">
                                <div>
                                    <button>
                                        SCOPRI TUTTI I NEGOZI
                                </button>
                                </div>
                                </Link>
                            </div>
                        </div>
                        <ShowCaseBlockTop />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

export default connect(mapStateToProps)(Home);
