import React, {Component} from 'react';
import StoriaBanner from "../../images/storia-landing.png";
import ReachUs from './ReachUs/ReachUs';
import Map from "../../components/UI/Maps/Map";
import Slider from "react-slick";

import {ReactComponent as Auto} from "../../images/noun-car-2168346.svg";
import {ReactComponent as Bus} from "../../images/noun-bus-417069.svg";
import {ReactComponent as Train} from "../../images/noun-train-2699168.svg";

import "./find-us.styles.scss";

class FindUs extends Component {

    state = {
        selected: "Torino"
    }

    render() {
        const {selected} = this.state;
        return (
            <>
                <div className="storia-container banner-block-mob">
                    <p className="storia-mob-txt">DOVE SIAMO</p>
                    <img src={StoriaBanner} alt="Dove siamo" style={{width: "100%", objectFit: "contain"}}
                         className="banner-img-find-us"/>
                </div>
                <div className="find-us-container">
                    <div className="how-to-reach-us">
                        <div className="how-to-reach-us-title">Come raggiungerci</div>
                        <div className="options">
                            <span onClick={() => this.setState({selected: "Torino"})}
                                  className={`${selected === "Torino" ? "selected" : ""}`}>Torino</span>
                            <span onClick={() => this.setState({selected: "Milano"})}
                                  className={`${selected === "Milano" ? "selected" : ""}`}>Milano</span>
                        </div>
                    </div>
                    <div className="reach-us-container">
                        <Slider {...settings}>
                            {this.state.selected === 'Torino' &&  <ReachUs title="AUTO" icon={<Auto/>}>
                                Circa 20 Km , S.S. 10 da zona "Sassi" - Corso Casale ( stazione Tranvia a Dentiera
                                Sassi-Superga) immettersi sulla strada del Traforo del Pino (SS10) . Circa 10 km dopo il
                                traforo si entra in Chieri centro.
                            </ReachUs>}
                            {this.state.selected === 'Milano' &&  <ReachUs title="AUTO" icon={<Auto/>}>
                                Autostrada A21 Torino-Piacenza , uscita Villanova d'Asti o alternativamente A4 Torino
                                -Milano uscita Corso Giulio Cesare e poi Lungo Stura Lazio direzione Chieri.
                            </ReachUs>}
                            {this.state.selected === 'Torino' &&   <ReachUs title="BUS" icon={<Bus/>}>
                                I collegamenti in autobus per Chieri sono serviti dalla GTT di Torino , la linea di
                                principale collegamento che opera sia feriale che festivo è la linea 30 con capolinea da
                                Torino in Corso San Maurizio dopo via Bava.
                            </ReachUs>}
                            {this.state.selected === 'Milano' && <ReachUs title="BUS" icon={<Bus/>}>
                                Servirsi delle diverse linee di autobus per Torino e successivamente indirizzarsi per le
                                coincidenze al capolinea della linea 30.
                            </ReachUs>}
                            {this.state.selected === 'Torino' &&  <ReachUs title="TRENO" icon={<Train/>}>
                                Dalla stazione di Torino Porta Susa prendere la linea " Canavesana" SFM1 - P.Susa -
                                Torino Lingotto- Trofarello - Chieri.
                            </ReachUs>}
                            {this.state.selected === 'Milano' && <ReachUs title="TRENO" icon={<Train/>}>
                                Prendere qualsiasi linea ferroviaria per Torino e scendere a Torino Porta Susa.
                            </ReachUs>}
                        </Slider>
                    </div>
                </div>

                <div className="container-map-find-us map-container">
                    <Map lat={45.0114252} lng={7.7848349} zoom={9}/>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center find-us-btn-div">
                    <a href={`https://maps.google.com/?q=${45.0114252},${7.7848349}`} target="__blank">
                        <button className="find-us-btn outline">
                            Apri in google maps
                        </button>
                    </a>
                </div>
            </>
        );
    }
}

function appendDots(dots) {
    return (<div>
        <div className="carousel-dots">
            <ul>{dots}</ul>
        </div>
    </div>);
}

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    appendDots: appendDots,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
            }
        },
    ]
};

export default FindUs;