import React, {Component} from 'react';
import ShopCard from '../../../components/Cards/ShopCard';
import {storesService} from "../../../services/basis/StoresService"

import "../ShowCase/HomeShowcase.scss";
import "../../Shops/Shops.scss";


class HomeShowcase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            stores: []
        }
    }

    componentDidMount() {
        storesService.stores(0, "")
            .then(res => {
                console.log(res)
                this.setState({
                    isLoaded: true,
                    stores: res.data.stores
                })
            })

    }

    render() {
        return (
            <div className="justify-content-center" style={{paddingTop: "unset"}}>
                <div className="row mt-2">
                    {this.state.stores.map((store) => (
                        <div key={store.id} className="col-12 col-md-6 col-lg-3">
                            <ShopCard store={store}/>
                        </div>))}
                </div>
            </div>
        )
    }
}

export default HomeShowcase;