export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const IS_LOGGED = "IS_LOGGED"
export const USER_UPDATE = "USER_UPDATE"
export const USER_DELETE = "USER_DELETE"
export const COORDINATE = "COORDINATE"
export const OVERLAY = "OVERLAY"
export const CLOSE_FREEZE_POPUP = "CLOSE_FREEZE_POPUP"
export const SHOW_LOGIN = "SHOW_LOGIN"

