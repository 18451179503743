import React from "react"
import Date from "./Date";
import chieriImage from "../../images/small.png";
import {Translation} from "react-i18next";

import "./News.scss"

const NewsCard = (props) => {
    return (
        <Translation>
            {t =>
                <>
                    <div className="news-card" style={{...props.style}}>
                        <img src={chieriImage} alt="" style={{width: "100%"}}/>
                    </div>
                    <div className="news-card-date">
                        <Date/>
                    </div>
                    <div className="news-details-side-text">
                        <p>
                            Lorem ipsum dolor sit amet,
                            consectetuer adipiscing elit.
                            Aenean commodo ligula eget dolor.
                        </p>
                    </div>
                </>
            }
        </Translation>
    );
}

export default NewsCard;