import React, {Component} from 'react';
import StoriaBanner from "../../images/storia-landing.png";
import StoriaGridImg from "../../images/storia-mob-grid-img.png";
import ShowCaseBlockTop from "../Home/ShowCase/ShowCaseBlockTop";
import NewsCard from "./NewsCard";
import {Link} from "react-router-dom";

import "./News.scss";

class News extends Component {
    state = {
        isMobile: window.innerWidth < 1024
    }

    constructor() {
        super();
        window.addEventListener("resize", (e) => {
            this.setState({isMobile: e.target.innerWidth < 1024});
        });
    }

    render() {
        if (!this.state.isMobile) {
            return (
                <>
                    <div className="banner-block-mob">
                        <p className="storia-mob-txt">NEWS</p>
                        <img src={StoriaBanner} alt="storia banner" className="d-flex" style={{width: "100%"}}/>
                    </div>
                    <div>
                        <ShowCaseBlockTop/>
                    </div>
                    <div className="news-container">
                        <div className="row container-fluid card-container-news">
                            <div className="col-12 col-md-6 col-lg-4">
                                <Link to="news/details" className="link">
                                    <NewsCard/>
                                </Link>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard/>
                                </Link>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard/>
                                </Link>
                            </div>
                        </div>
                        <div className="row container-fluid card-container-news">
                            <div className="col-12 col-md-6 col-lg-4">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard/>
                                </Link>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard/>
                                </Link>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard/>
                                </Link>
                            </div>
                        </div>
                        <div className="row container-fluid card-container-news">
                            <div className="col-12 col-md-6 col-lg-4">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard/>
                                </Link>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard/>
                                </Link>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div>
                        <div className="banner-block-mob mb-5">
                            <p className="storia-mob-txt">NEWS</p>
                            <img src={StoriaGridImg} alt="storia banner" className="d-flex"
                                 style={{width: "100%", objectFit: "cover"}}/>
                        </div>
                    </div>
                    <div>
                        <ShowCaseBlockTop/>
                    </div>
                    <div className="container">
                        <div className="row d-flex flex-row justify-content-center align-items-center">
                            <div className="col-12">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard className="news-detail-image-card"/>
                                </Link>
                            </div>
                            <div className="col-12">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard className="news-detail-image-card"/>
                                </Link>
                            </div>
                        </div>
                        <div className="row d-flex flex-row justify-content-center align-items-center">
                            <div className="col-12">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard className="news-detail-image-card"/>
                                </Link>
                            </div>
                            <div className="col-12">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard className="news-detail-image-card"/>
                                </Link>
                            </div>
                        </div>
                        <div className="row d-flex flex-row justify-content-center align-items-center">
                            <div className="col-12">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard className="news-detail-image-card"/>
                                </Link>
                            </div>
                            <div className="col-12">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard className="news-detail-image-card"/>
                                </Link>
                            </div>
                        </div>
                        <div className="row d-flex flex-row justify-content-center align-items-center">
                            <div className="col-12">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard className="news-detail-image-card"/>
                                </Link>
                            </div>
                            <div className="col-12">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard className="news-detail-image-card"/>
                                </Link>
                            </div>
                        </div>
                        <div className="row d-flex flex-row justify-content-center align-items-center">
                            <div className="col-12">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard className="news-detail-image-card"/>
                                </Link>
                            </div>
                            <div className="col-12">
                                <Link to="news/details" style={{textDecoration: "none"}}>
                                    <NewsCard className="news-detail-image-card"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}

export default News;