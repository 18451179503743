import React, { Component } from 'react'
import { Map as LeafletMap, TileLayer, Marker } from 'react-leaflet';

export default class Map extends Component {
    render() {
        const position = [this.props.lat, this.props.lng]
        return (
            <LeafletMap center={position} zoom={this.props.zoom}
                // doubleClickZoom={false}
                // closePopupOnClick={false}
                // dragging={false}
                // zoomSnap={false}
                // zoomDelta={false}
                // trackResize={false}
                // touchZoom={false}
                scrollWheelZoom={false}
            >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={position}>
                    {/*
                        <Popup>
                            A pretty CSS3 popup. <br /> Easily customizable.
                        </Popup>
                     */}
                </Marker>
            </LeafletMap>
        )
    }
}


