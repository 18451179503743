const config = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    maxSize: 30,
    DEFAULT_STORE_ID : "001",
    DEFAULT_STORE_DESCRIPTION : "Milano",
    DEFAULT_STATE: "",
    DEFAULT_STATE_ID: "",
    SHOW_STATE_DROPDOWN : false,
    SHOW_CART_LISTS: false,

    ENABLED_SLIST: false,
    ENABLED_PAYMENTS: false
}

export default config;