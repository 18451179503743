import React, {Component} from 'react';
import Slider from "react-slick";
import {ReactComponent as ArrowRight} from "../../images/arrow_right.svg";
import {ReactComponent as ArrowLeft} from "../../images/arrow_left.svg";
import StoriaGridImg1 from "../../images/storia-slider-img1.png";
import StoriaGridImg2 from "../../images/storia-slider-img2.png";
import StoriaGridImg3 from "../../images/chieri-chiesa.png";
import "./Storia.scss"

class StoriaSlider extends Component {
    render() {
        return (
            <div className="storia-slider">
                <Slider {...settings}>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="storia-grid-img-slider">
                            <img src={StoriaGridImg1} className="storia-grid-image" alt=""/>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="storia-grid-img-slider">
                            <img src={StoriaGridImg2} className="storia-grid-image" alt=""/>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="storia-grid-img-slider">
                            <img src={StoriaGridImg3} className="storia-grid-image" alt=""/>
                        </div>
                    </div>

                </Slider>
                <div className="carousel-dots"></div>
            </div>
        );
    }
}

function appendDots(dots) {
    return (<div>
        <div className="carousel-dots">
            <ul>{dots}</ul>
        </div>
    </div>);
}

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    appendDots: appendDots,
    nextArrow: <SampleArrow><ArrowRight/></SampleArrow>,
    prevArrow: <SampleArrow><ArrowLeft/></SampleArrow>,
    responsive: [
        {
            breakpoint: 1025,
            settings: {
                nextArrow: <SampleArrow><ArrowRight/></SampleArrow>,
                prevArrow: <SampleArrow><ArrowLeft/></SampleArrow>,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
            }
        },
        {
            breakpoint: 576,
            settings: {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
            }
        },
    ]
};

function SampleArrow(props) {
    const {className, style, onClick, children} = props;
    return (
        <div className={className} style={{...style}} onClick={onClick}>
            {children}
        </div>
    );
}

export default StoriaSlider;