import React, {Component} from 'react';
import LandingHomeImg from "../../images/rectangle@3x.jpg";
import StoriaGridImg from "../../images/storia-grid.jpg";
import StoriaBanner from "../../images/storia-banner.jpg";
import StoriaImg from "../../images/storia-landing-img.png";
import StoriaMobImg from "../../images/storia-mob-img.jpg";

import "./Storia.scss"
import StoriaSlider from "./StoriaSlider";

class StoriaContent extends Component {
    state = {
        isMobile: window.innerWidth <= 1024
    }

    constructor() {
        super();
        window.addEventListener("resize", (e) => {
            this.setState({isMobile: e.target.innerWidth <= 1024});
        });
    }

    render() {
        if (!this.state.isMobile) {
            return (
                <>
                    <div className="storia container d-flex flex-column justify-content-center">
                        {/*<div className="storia-title mb-1">*/}
                        {/*    Lorem ipsum dolor sit amet, consectetuer<br/>*/}
                        {/*    adipiscing elit. Aenean commodo ligula eget dolor*/}
                        {/*</div>*/}
                        {/*<div className="storia-subtitle mt-1">*/}
                        {/*    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.*/}
                        {/*    Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur*/}
                        {/*    ridiculus mus.*/}
                        {/*</div>*/}
                        {/*<div>*/}
                        {/*    <img className="storia-landing-img" src={LandingHomeImg}*/}
                        {/*         alt=""/>*/}
                        {/*</div>*/}
                        <div className="container storia-text">
                            <p>
                                Già municipium in età romana con il nome di Carreum Potentia , la città nell'XI secolo
                                era feudo di Landolfo, vescovo di Torino; alleata di Asti, nel 1155 fu distrutta dal
                                Barbarossa.

                                Passata nel 1347 agli Acaja e nel 1418 ai Savoia, prosperò come centro tessile, specie
                                per la produzione del fustagno (nell'ex convento di S. Chiara, già opificio Levi, è
                                ospitato il primo nucleo della raccolta del Museo del Tessile chierese).

                                In quello stesso periodo grande impulso ebbero la produzione artistica e il rinnovamento
                                architettonico. Tutta l’organizzazione sociale ebbe a ruotare attorno all‘ “Università
                                del fustagno” la corporazione degli imprenditori tessili . Il lavoro di telai nella
                                città di Chieri ha costituito dal XVI al XX secolo la base del sistema economico e con
                                l’agricoltura hanno rappresentato il motore di sviluppo dell’intera città

                            </p>
                            <p>
                                In piazza Cavour prospettano la chiesa di S. Antonio Abate, suggestivo edificio rococò
                                (1767), e la chiesa dei Ss. Bernardino e Rocco (cupola di Bernardo Antonio Vittone,
                                1740-44, e facciata di Bernardino Quadri e Mario Ludovico Quarini, 1792).

                                Il Duomo è uno degli edifici più interessanti del '400 piemontese. Su via Vittorio
                                Emanuele II e nelle strade adiacenti si individuano resti di case e palazzi di epoca
                                tardo-medievale.

                                Nel cuore della città l'arco trionfale, eretto nel 1580 su progetto di Pellegrino
                                Tibaldi per il passaggio del duca Emanuele Filiberto, poi parzialmente modificato, nel
                                1761, dal Vittone.

                                Poco oltre si trova invece la settecentesca chiesa di S. Filippo Neri, opera del
                                chierese Mario Ludovico Quarini e, al N. 71 di Via Vittorio Emanuele Palazzo Solaro,
                                dimora tre-quattrocentesca di una potente famiglia di banchieri.

                                La gotica chiesa di S. Domenico, ampiamente restaurata nel corso del 1871, presenta in
                                facciata un portale sovrastato da una trifora; sul fianco il campanile cuspidato risale
                                al 1381.


                            </p>
                        </div>
                        <StoriaSlider/>
                    {/*    <div className="container storia-text">*/}
                    {/*        <p>*/}
                    {/*            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget*/}
                    {/*            dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes,*/}
                    {/*            nascetur ridiculus mus.*/}
                    {/*            Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate*/}
                    {/*            eget, arcu. In enim justo, rhoncus ut,*/}
                    {/*            imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer*/}
                    {/*            tincidunt.*/}

                    {/*            Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.*/}
                    {/*            Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate*/}
                    {/*            eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam*/}
                    {/*            dictum felis eu pede mollis pretium.*/}
                    {/*            Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate*/}
                    {/*            eget,*/}
                    {/*            arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum*/}
                    {/*            felis eu*/}
                    {/*            pede mollis pretium. Integer tincidunt.*/}
                    {/*            Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.*/}
                    {/*            Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate*/}
                    {/*            eget,*/}
                    {/*            arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum*/}
                    {/*            felis eu*/}
                    {/*            pede mollis pretium.*/}

                    {/*            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget*/}
                    {/*            dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes,*/}
                    {/*            nascetur ridiculus mus.*/}
                    {/*            Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate*/}
                    {/*            eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam*/}
                    {/*            dictum felis eu pede mollis pretium. Integer tincidunt.*/}

                    {/*            Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.*/}
                    {/*            Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate*/}
                    {/*            eget,*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*    <div className="d-flex flex-row">*/}
                    {/*        <div className="storia-grid-img mr-2">*/}
                    {/*            <img src={StoriaGridImg} className="storia-grid-image" alt=""/>*/}
                    {/*        </div>*/}
                    {/*        <div className=" storia-grid-img ml-3">*/}
                    {/*            <img src={StoriaGridImg} className="storia-grid-image" alt=""/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="d-flex flex-row mt-5">*/}
                    {/*        <div className="storia-grid-img">*/}
                    {/*            <img src={StoriaGridImg} className="storia-grid-image" alt=""/>*/}
                    {/*        </div>*/}
                    {/*        <div className="storia-grid-img storia-grid-text">*/}
                    {/*            <div className="ml-3">*/}
                    {/*                <p>*/}
                    {/*                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula*/}
                    {/*                    eget*/}
                    {/*                    dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient*/}
                    {/*                    montes,*/}
                    {/*                    nascetur ridiculus mus.*/}
                    {/*                    Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,*/}
                    {/*                    vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae,*/}
                    {/*                    justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt.*/}

                    {/*                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula*/}
                    {/*                    eget*/}
                    {/*                    dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient*/}
                    {/*                    montes,*/}
                    {/*                    nascetur ridiculus mus.*/}
                    {/*                    Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,*/}
                    {/*                    vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae,*/}
                    {/*                    justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt.*/}

                    {/*                    Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.*/}
                    {/*                    Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,*/}
                    {/*                    vulputate eget,*/}
                    {/*                </p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="d-flex flex-row">*/}
                    {/*        <div className="storia-grid-text">*/}
                    {/*            <div>*/}
                    {/*                <p>*/}
                    {/*                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula*/}
                    {/*                    eget*/}
                    {/*                    dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient*/}
                    {/*                    montes,*/}
                    {/*                    nascetur ridiculus mus.*/}
                    {/*                    Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,*/}
                    {/*                    vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae,*/}
                    {/*                    justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt.*/}

                    {/*                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula*/}
                    {/*                    eget*/}
                    {/*                    dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient*/}
                    {/*                    montes,*/}
                    {/*                    nascetur ridiculus mus.*/}
                    {/*                    Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,*/}
                    {/*                    vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae,*/}
                    {/*                    justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt.*/}

                    {/*                    Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.*/}
                    {/*                    Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,*/}
                    {/*                    vulputate eget,*/}
                    {/*                </p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="storia-grid-img">*/}
                    {/*            <img src={StoriaGridImg} className="storia-grid-image" alt=""/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="d-fluid">*/}
                    {/*    <img src={StoriaBanner} alt="" className="storia-banner"/>*/}
                    {/*</div>*/}
                    {/*<div className="container d-flex justify-content-center">*/}
                    {/*    <p className="storia-text-bottom-banner">*/}
                    {/*        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.*/}
                    {/*        Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur*/}
                    {/*        ridiculus mus.*/}
                    {/*        Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate*/}
                    {/*        eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum*/}
                    {/*        felis eu pede mollis pretium. Integer tincidunt.*/}

                    {/*        Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.*/}
                    {/*        Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate*/}
                    {/*        eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum*/}
                    {/*        felis eu pede mollis pretium.*/}
                    {/*        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.*/}
                    {/*        Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur*/}
                    {/*        ridiculus mus.*/}
                    {/*        Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate*/}
                    {/*        eget,*/}
                    {/*        arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum*/}
                    {/*        felis eu*/}
                    {/*        pede mollis pretium. Integer tincidunt.*/}
                    {/*        Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.*/}
                    {/*        Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate*/}
                    {/*        eget,*/}
                    {/*        arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum*/}
                    {/*        felis eu*/}
                    {/*        pede mollis pretium.*/}
                    {/*        Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate*/}
                    {/*        eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum*/}
                    {/*        felis eu pede mollis pretium. Integer tincidunt.*/}

                    {/*        Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.*/}
                    {/*        Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate*/}
                    {/*        eget,*/}
                    {/*    </p>*/}
                    </div>
                </>
            );
        } else {
            return (
                <div className="storia container d-flex flex-column justify-content-center">
                    {/*<div className="storia-title-mob">*/}
                    {/*    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.*/}
                    {/*    Aenean commodo ligula eget dolor*/}
                    {/*</div>*/}
                    {/*<div className="storia-subtitle-mob">*/}
                    {/*    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.*/}
                    {/*    Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus*/}
                    {/*    mus.*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <img src={StoriaImg} alt="" className="storia-block-img1"/>*/}
                    {/*</div>*/}
                    <div className="storia-txt-block1-mob mt-5 mb-4">
                        <p>
                            Già municipium in età romana con il nome di Carreum Potentia , la città nell'XI secolo
                            era feudo di Landolfo, vescovo di Torino; alleata di Asti, nel 1155 fu distrutta dal
                            Barbarossa.

                            Passata nel 1347 agli Acaja e nel 1418 ai Savoia, prosperò come centro tessile, specie
                            per la produzione del fustagno (nell'ex convento di S. Chiara, già opificio Levi, è
                            ospitato il primo nucleo della raccolta del Museo del Tessile chierese).

                            In quello stesso periodo grande impulso ebbero la produzione artistica e il rinnovamento
                            architettonico. Tutta l’organizzazione sociale ebbe a ruotare attorno all‘ “Università
                            del fustagno” la corporazione degli imprenditori tessili . Il lavoro di telai nella
                            città di Chieri ha costituito dal XVI al XX secolo la base del sistema economico e con
                            l’agricoltura hanno rappresentato il motore di sviluppo dell’intera città

                        </p>
                        <p>
                            In piazza Cavour prospettano la chiesa di S. Antonio Abate, suggestivo edificio rococò
                            (1767), e la chiesa dei Ss. Bernardino e Rocco (cupola di Bernardo Antonio Vittone,
                            1740-44, e facciata di Bernardino Quadri e Mario Ludovico Quarini, 1792).

                            Il Duomo è uno degli edifici più interessanti del '400 piemontese. Su via Vittorio
                            Emanuele II e nelle strade adiacenti si individuano resti di case e palazzi di epoca
                            tardo-medievale.

                            Nel cuore della città l'arco trionfale, eretto nel 1580 su progetto di Pellegrino
                            Tibaldi per il passaggio del duca Emanuele Filiberto, poi parzialmente modificato, nel
                            1761, dal Vittone.

                            Poco oltre si trova invece la settecentesca chiesa di S. Filippo Neri, opera del
                            chierese Mario Ludovico Quarini e, al N. 71 di Via Vittorio Emanuele Palazzo Solaro,
                            dimora tre-quattrocentesca di una potente famiglia di banchieri.

                            La gotica chiesa di S. Domenico, ampiamente restaurata nel corso del 1871, presenta in
                            facciata un portale sovrastato da una trifora; sul fianco il campanile cuspidato risale
                            al 1381.


                        </p>
                    </div>
                    <StoriaSlider/>
                    {/*<div className="mt-3">*/}
                    {/*    <img src={StoriaMobImg} alt="" className="storia-block-img2"/>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <img src={StoriaMobImg} alt="" className="storia-block-img2"/>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <img src={StoriaMobImg} alt="" className="storia-block-img2"/>*/}
                    {/*</div>*/}
                    {/*<div className="storia-txt-block1">*/}
                    {/*    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.*/}
                    {/*    Aenean commodo ligula eget dolor. Aenean massa. Cum*/}
                    {/*    sociis natoque penatibus et magnis dis parturient montes,*/}
                    {/*    nascetur ridiculus mus.*/}
                    {/*    Nulla consequat massa quis enim. Donec pede justo,*/}
                    {/*    fringilla vel, aliquet nec, vulputate eget, arcu. In enim*/}
                    {/*    justo, rhoncus ut, imperdiet a, venenatis vitae, justo.*/}
                    {/*    Nullam dictum felis eu pede mollis pretium.*/}
                    {/*    Integer tincidunt.*/}

                    {/*    Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.*/}
                    {/*    Nulla consequat massa quis enim. Donec pede justo,*/}
                    {/*    fringilla vel, aliquet nec, vulputate eget, arcu. In enim*/}
                    {/*    justo, rhoncus ut, imperdiet a, venenatis vitae, justo.*/}
                    {/*    Nullam dictum felis eu pede mollis pretium.*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <img src={StoriaMobImg} alt="" className="storia-block-img2 mt-4"/>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <div className="storia-txt-block1">*/}
                    {/*        Lorem ipsum dolor sit amet, consectetuer adipiscing elit.*/}
                    {/*        Aenean commodo ligula eget dolor. Aenean massa. Cum*/}
                    {/*        sociis natoque penatibus et magnis dis parturient montes,*/}
                    {/*        nascetur ridiculus mus.*/}
                    {/*        Nulla consequat massa quis enim. Donec pede justo,*/}
                    {/*        fringilla vel, aliquet nec, vulputate eget, arcu. In enim*/}
                    {/*        justo, rhoncus ut, imperdiet a, venenatis vitae, justo.*/}
                    {/*        Nullam dictum felis eu pede mollis pretium.*/}
                    {/*        Integer tincidunt. <br/>*/}

                    {/*        Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.*/}
                    {/*        Nulla consequat massa quis enim. Donec pede justo,*/}
                    {/*        fringilla vel, aliquet nec, vulputate eget, arcu. In enim*/}
                    {/*        justo, rhoncus ut, imperdiet a, venenatis vitae, justo.*/}
                    {/*        Nullam dictum felis eu pede mollis pretium. <br/>*/}

                    {/*        Lorem ipsum dolor sit amet, consectetuer adipiscing elit.*/}
                    {/*        Aenean commodo ligula eget dolor. Aenean massa. Cum*/}
                    {/*        sociis natoque penatibus et magnis dis parturient montes,*/}
                    {/*        nascetur ridiculus mus.*/}
                    {/*        Nulla consequat massa quis enim. Donec pede justo,*/}
                    {/*        fringilla vel, aliquet nec, vulputate eget, arcu. In enim*/}
                    {/*        justo, rhoncus ut, imperdiet a, venenatis vitae, justo.*/}
                    {/*        Nullam dictum felis eu pede mollis pretium.*/}
                    {/*        Integer tincidunt. <br/>*/}

                    {/*        Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.*/}
                    {/*        Nulla consequat massa quis enim. Donec pede justo,*/}
                    {/*        fringilla vel, aliquet nec, vulputate eget, arcu. In enim*/}
                    {/*        justo, rhoncus ut, imperdiet a, venenatis vitae, justo.*/}
                    {/*        Nullam dictum felis eu pede mollis pretium.*/}

                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <img src={StoriaMobImg} alt="" className="storia-block-img2 mt-5"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

            )
        }
    }
}

export default StoriaContent;