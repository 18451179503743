import React, { Component } from 'react';
import StoriaBanner from "../../images/storia-banner.png"
import StoriaContent from "./StoriaContent";
import StoriaGridImg from "../../images/storia-mob-grid-img.png"

import "./Storia.scss"

class Storia extends Component {
    state = {
        isMobile: window.innerWidth <= 1024
    }

    constructor() {
        super();
        window.addEventListener("resize", (e) => {
            this.setState({isMobile: e.target.innerWidth <= 1024});
        });
    }
    render() {
        if (!this.state.isMobile) {
            return (<>
                <div className="storia-container banner-block-mob">
                    <p className="storia-mob-txt">LA NOSTRA STORIA</p>
                    <img src={StoriaBanner} alt="storia banner" className="d-flex" style={{ width: "100%"}} />
                </div>
                <StoriaContent />
            </>
            );
        } else {
            return (
                <div>
                    <div className="banner-block-mob">
                        <p className="storia-mob-txt">LA NOSTRA STORIA</p>
                        <img src={StoriaGridImg} alt="storia banner" className="d-flex" style={{ width: "100%", objectFit: "cover" }} />
                    </div>
                    <StoriaContent />
                </div>
            )
        }
    }
}

export default Storia;