import React, { Component } from 'react';
import ChiSiamoBanner from "../../images/chi-siamo-banner.png";
import StoriaGridImg from "../../images/storia-mob-grid-img.png";
import WhoWeAreContent from "./WhoWeAreContent";

import "../Stories/Storia.scss";


class Storia extends Component {
    state = {
        isMobile: window.innerWidth <= 1024
    }

    constructor() {
        super();
        window.addEventListener("resize", (e) => {
            this.setState({isMobile: e.target.innerWidth <= 1024});
        });
    }
    render() {
        if (!this.state.isMobile) {
            return (<>
                    <div className="storia-container banner-block-mob">
                        <p className="storia-mob-txt">CHI SIAMO</p>
                        <img src={ChiSiamoBanner} alt="storia banner" className="d-flex" style={{ width: "100%"}} />
                    </div>
                    <WhoWeAreContent />
                </>
            );
        } else {
            return (
                <div>
                    <div className="banner-block-mob">
                        <p className="storia-mob-txt">CHI SIAMO</p>
                        <img src={ChiSiamoBanner} alt="storia banner" className="d-flex" style={{ width: "100%", objectFit: "cover", height:"200px" }} />
                    </div>
                    <WhoWeAreContent />
                </div>
            )
        }
    }
}

export default Storia;