import React from "react";
import {Navbar, Nav} from 'react-bootstrap'
import {Link} from "react-router-dom";
import {LinkContainer} from "react-router-bootstrap";
import Logo from "../../../images/chierilogo@3x.png";
import {Translation} from "react-i18next";
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux"
import MyStores from "../../../images/MyStores.png";
//import {ReactComponent as UserIcon} from '../../../images/shape.svg';
import * as actionCreators from "../../../redux/store/actions"

import "./header.styles.scss";

class Header extends React.Component {
    render() {
        const {location} = this.props;
        return (
            <Translation>
                {t =>
                    <>
                        <div className="row no-gutters header-top">
                            <div className="col pin pr-3">
                                <a href="https://my-stores.it/mallDetails/1e9e3a1c-7ab5-4f11-9415-45c5ae6e6a2e"
                                   className="link"
                                   rel="noopener noreferrer"
                                   target="_blank" style={{textDecoration: "none"}}>
                                <img src={MyStores} alt=""/>
                                </a>
                            </div>
                        </div>
                        <Navbar collapseOnSelect="true" sticky="top" expand="lg">
                            <Navbar.Brand>
                                <Link to="/">
                                    <img src={Logo} className="d-inline-block align-top" alt="Chieri"/>
                                </Link>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto"/>
                                <Nav className="actions">
                                    <LinkContainer to="/"><Nav.Link href="/">
                                        <span
                                            className={`${location.pathname === "/" ? "actived" : ""} nav-menu`}>Home</span>
                                    </Nav.Link></LinkContainer>
                                    <LinkContainer to="/whoWeAre"><Nav.Link href="/whoWeAre">
                                        <span
                                            className={`${location.pathname === "/whoWeAre" ? "actived" : ""} nav-menu`}>Chi&nbsp;Siamo</span>
                                    </Nav.Link></LinkContainer>
                                    <LinkContainer to="/storia"><Nav.Link href="/storia">
                                        <span
                                            className={`${location.pathname === "/storia" ? "actived" : ""} nav-menu`}>Storia</span>
                                    </Nav.Link></LinkContainer>
                                    <LinkContainer to="/news"><Nav.Link href="/news">
                                        <span
                                            className={`${location.pathname === "/news" ? "actived" : ""} nav-menu`}>News</span>
                                    </Nav.Link></LinkContainer>
                                    <LinkContainer to="/negozi"><Nav.Link href="/negozi">
                                        <span
                                            className={`${location.pathname === "/negozi" ? "actived" : ""} nav-menu`}>Negozi</span>
                                    </Nav.Link></LinkContainer>
                                    <LinkContainer to="/findUs"><Nav.Link href="/findUs">
                                        <span
                                            className={`${location.pathname === "/findUs" ? "actived" : ""} nav-menu`}>Dove&nbsp;Siamo</span>
                                    </Nav.Link></LinkContainer>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </>
                }
            </Translation>
        );
    }
}


const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showModalLogin: () => dispatch(actionCreators.showLogin(true)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
