import React, {Component} from 'react';
import ImgChieriHome from "../../images/chieri-home-img.png";
import {Link} from "react-router-dom";

import "./category-slider.styles.scss";


class CategorySlider extends Component {
    state = {
        isMobile: window.innerWidth <= 1024
    }

    constructor() {
        super();
        window.addEventListener("resize", (e) => {
            this.setState({isMobile: e.target.innerWidth <= 1024});
        });
    }

    render() {
        if (!this.state.isMobile) {
            return (
                <div className="d-flex">
                    <div className="col-md-4 offset-1 landing-page">
                        <div className="landing-page-text-title mt-3 p-1">
                            Chieri,tra tesori
                            architettonici e Freisa
                        </div>
                        <div className="landing-page-text">
                            <p>Pochi minuti dividono Chieri da Torino. Tra panorami e vigne si giunge in quella che
                                nell’XI secolo era feudo di Landolfo, vescovo di Torino. Prosperò come centro tessile,
                                oggi celebrato nel Museo del tessile chierese, e proprio in quel luminoso periodo ebbe
                                grande impulso anche quello sviluppo architettonico che ancora oggi si ammira visitando
                                la città.
                            </p>
                        </div>
                        <div className="landing-button">
                            <Link to="/whoWeAre">
                            <button>SCOPRI</button>
                            </Link>
                        </div>


                    </div>
                    <div className="landing-page-img col-md-4 offset-1">
                        <img src={ImgChieriHome} style={{width: "546px",
                            height: "368px"}}
                             alt=""/>
                    </div>

                </div>
            );
        } else {
            return (
                <div className="d-flex flex-column landing-page-mob">
                    <div className="landing-page-text-title-mob p-1">
                        Chieri,tra tesori
                        architettonici e Freisa
                    </div>
                    <div className="landing-page-img-mob">
                        <img src={ImgChieriHome}
                             alt=""/>
                    </div>
                    <div className="landing-page-text-mob mr-3 ml-3">
                        <p>Pochi minuti dividono Chieri da Torino. Tra panorami e vigne si giunge in quella che
                            nell’XI secolo era feudo di Landolfo, vescovo di Torino. Prosperò come centro tessile,
                            oggi celebrato nel Museo del tessile chierese, e proprio in quel luminoso periodo ebbe
                            grande impulso anche quello sviluppo architettonico che ancora oggi si ammira visitando
                            la città.</p>
                    </div>
                    <div className="landing-button-mob">
                        <Link to="/whoWeAre">
                        <button>SCOPRI</button>
                        </Link>
                    </div>
                </div>
            )
        }
    }
}

export default CategorySlider;