import * as actionTypes from "./actionTypes"
import { authenticationService } from "../../../services/basis/AuthenticationService"
import { history } from "../../../App"
import toaster from "../../../utils/toaster"
import swal from "../../../utils/swal"

export const loginAction = (user, showModal) => {
    return {
        type: actionTypes.LOGIN,
        user: user,
        showLogin: showModal
    }
}

export const login = (data) => {
    return (dispatch, getState) => {
        authenticationService.login(data)
            .then(response => {
                dispatch(loginAction(response.data, false))
            }).catch((error) => {
                dispatch(loginAction(null, true))
                toaster.error("Credenziali errate")
            })
    }
};

export const logoutAction = () => {
    return {
        type: actionTypes.LOGOUT,
        user: null
    }
}

export const logout = () => {
    return dispatch => {
        history.push("/")
        dispatch(logoutAction())
        authenticationService.logout()
            .then(response => {
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const isLoggedAction = (user) => {
    return {
        type: actionTypes.IS_LOGGED,
        user: user
    }
}

export const isLogged = () => {
    return dispatch => {
        authenticationService.isLogged()
            .then(response => {
                dispatch(isLoggedAction(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const coordinateAction = (latitude, longitude) => {
    return {
        type: actionTypes.COORDINATE,
        latitude: latitude,
        longitude: longitude
    }
}

export const coordinate = (latitude, longitude) => {
    return dispatch => {
        dispatch(coordinateAction(latitude, longitude))
    }
};

export const updateUserAction = (user) => {
    return {
        type: actionTypes.USER_UPDATE,
        user: user
    }
}

export const updateUser = (data) => {
    return dispatch => {
        authenticationService.updateUser(data)
            .then(response => {
                dispatch(updateUserAction(response.data))
                swal.success('Profilo aggiornato con successo');
            }).catch((error) => {
                swal.error('Errore durante l\'aggiornamento del profilo');
                console.log(error);
            })
    }
};

export const overlayAction = (isActive) => {
    return {
        type: actionTypes.OVERLAY,
        showOverlay: isActive
    }
}

export const overlay = (isActive) => {
    return dispatch => {
        dispatch(overlayAction(isActive))
    }
};

export const showLogin = (show) => {
    return dispatch => {
        dispatch(showLoginAction(show))
    }
};

export const showLoginAction = (show) => {
    return {
        type: actionTypes.SHOW_LOGIN,
        showLogin: show
    }
}
