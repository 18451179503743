import React, {Component} from 'react';



class ShowCaseBlockBottom extends Component {
    state = {
        isMobile: window.innerWidth < 768
    }

    constructor() {
        super();
        window.addEventListener("resize", (e) => {
            this.setState({isMobile: e.target.innerWidth < 768});
        });
    }

    render() {
        if (!this.state.isMobile) {
            return (
                <div>
                    <div className="landing-banner-text-overlay  d-flex flex-column justify-content-center mt-5">
                        <img src="" alt=""/>
                        <div className="shady-banner-title mb-2">
                            My- Stores
                        </div>
                        <div className="shady-banner-text mt-3">
                            <p>Entra nel centro commerciale pìù grande d’Italia </p>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="container landing-banner-text-overlay  d-flex flex-column justify-content-center mt-5">
                    <div className="shady-banner-title-mob mb-1">
                        My- Stores
                    </div>
                    <div className="shady-banner-text mt-1">
                        <p>Entra nel centro commerciale pìù grande d’Italia </p>
                    </div>
                </div>
            )
        }
    }
}

export default ShowCaseBlockBottom;