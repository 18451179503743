import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import MainTemplate from './components/MainComponent/Template/MainTemplate';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import LoginPopup from './features/Login/LoginPopup/LoginPopup';
import Home from './features/Home/Home';
import Stories from "./features/Stories/Storia";
import News from "./features/News/News";
import NewsDetails from "./features/News/NewsDetails";
import Shops from "./features/Shops/Shops";
import FindUs from "./features/FindUs/FindUs";
import WhoWeAre from "./features/WhoWeAre/WhoWeAre";

import './App.scss';

// custom router
import { Router } from 'react-router'
import createBrowserHistory from 'history/createBrowserHistory'

// redux
import * as actionCreators from "./redux/store/actions/index"
import { connect } from "react-redux"

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faCalendarAlt, faSearch, faUser, faHeart, faShoppingCart, faBars, faUnlock, faStoreAlt, faPlus, faClipboardList, faCopy, faCreditCard,
  faMinus, faTimesCircle, faThumbsUp, faClipboardCheck, faLeaf, faSeedling, faPaw, faAngleLeft, faTrashAlt, faPlusCircle, faEdit, faChevronRight, faChevronDown
} from '@fortawesome/free-solid-svg-icons'



library.add(fab, faCalendarAlt, faSearch, faUser, faHeart, faShoppingCart, faBars, faUnlock, faStoreAlt, faClipboardList, faCopy, faChevronRight, faChevronDown,
  faPlus, faMinus, faTimesCircle, faThumbsUp, faClipboardCheck, faLeaf, faSeedling, faPaw, faAngleLeft, faTrashAlt, faPlusCircle, faEdit, faCreditCard, fab.faFacebookSquare)

// custom history
export const history = createBrowserHistory({ basename: "/app" });

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <ScrollToTop>
          <Switch>
          <MainTemplate>
                <Switch>
                  <Route exact path='/' component={Home} />
                  <Route exact path='/whoWeAre' component={WhoWeAre} />
                  <Route exact path='/storia' component={Stories} />
                  <Route exact path='/news' component={News} />
                  <Route exact path='/news/details' component={NewsDetails} />
                  <Route exact path='/negozi' component={Shops} />
                  <Route exact path='/findUs' component={FindUs} />
                </Switch>
              </MainTemplate>
          </Switch>
          <LoginPopup
            open={this.props.showLogin}
            closePopup={this.props.closePopup}
          />
        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    showLogin: state.user.showLogin
  };
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actionCreators.logout()),
    closePopup: () => dispatch(actionCreators.showLogin(false)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
